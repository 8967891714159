<script setup>
    import { ref, watch } from 'vue';

    import { gsap } from '@resn/gsap';

    import { PageState } from '~/providers/page/pageState';
    import { useAppStore } from '~/stores/app';

    const root = ref(null);
    const active = ref(true);
    const progress = ref(0);

    const app = useAppStore();

    const setActive = (val) => {
        const alpha = val ? 1 : 0;
        gsap.killTweensOf(root.value);
        gsap.to(root.value, {
            autoAlpha: alpha,
            duration: 1,
        });
    };

    const setPageState = (state) => {
        switch (state) {
            case PageState.LeaveStart:
            case PageState.LeaveComplete:
            case PageState.Preload:
            case PageState.PreloadComplete:
                active.value = true;
                break;
            case PageState.EnterStart:
                active.value = false;
                break;

            default:
                break;
        }
    };

    const setProgress = (val) => {
        progress.value = val;
    };

    watch(() => app.loadProgress, setProgress);
    watch(() => app.pageState, setPageState);
    watch(active, setActive);
</script>

<template>
    <div ref="root" class="pageLoader"></div>
</template>

<style lang="scss" scoped>
    @use '@/styles/shared.scss' as *;

    .pageLoader {
        @include fill(fixed);

        background-color: black;
        z-index: 1;
    }
</style>
